<template>
    <b-img :src="requireItemImage(itemType)"
           :alt="item.nom"
           :title="item.nom"
           v-b-popover.hover.html="popoverBody"
           class="collectable-item-img"/>
</template>

<script>
    import {sanitize} from 'dompurify';

    import {getItem, requireItemImage} from '@/util/collectableItem';
    import {formatDate}                from '@/util/date';

    export default {
        name: "CollectedItem",
        props: {
            itemType: {
                type: String,
                required: true
            },
            obtention: {
                default: false
            }
        },
        computed: {
            item() {
                return getItem(this.itemType);
            },
            popoverBody() {
                return this.obtention
                    ? sanitize(
                        '<p class="mb-0 text-center">' +
                        '     <em><small>Obtenu le ' + formatDate(this.obtention) + '</small></em>' +
                        '</p>' +
                        '<hr class="mt-1 mb-2 ml-4 mr-4"/>' +
                        this.item.description
                    )
                    : sanitize(this.item.description);
            }
        },
        methods: {requireItemImage}
    }
</script>

<style scoped>
    .collectable-item-img:hover {
        filter: drop-shadow(0 0 0.45rem black);
    }
</style>